import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import JobCard from "../components/JobCard";
import Checkbox from "../components/Checkbox";
import { HiOutlineSearch, HiX } from "react-icons/hi";

const JobList = (props) => {
  const { jobsData } = props;
  jobsData.sort((a, b) => b.date.timestamp - a.date.timestamp); // sort vacancies (show latest first)

  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get("title") || "");
  // Initialize the filter value with parameter from URL
  const [filterValue, setFilterValue] = useState({
    industry: searchParams.getAll("industry"),
    region: searchParams.getAll("region"),
    experienceLevel: searchParams.getAll("experienceLevel"),
    educationLevel: searchParams.getAll("educationLevel"),
  });
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  // mobilefilter
  useEffect(() => {
    if (mobileFiltersOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [mobileFiltersOpen]);

  // Filter the job data
  const filteredData = jobsData.filter((job) => {
    // Check if the job title matches the search query
    const isMatch = job.title.toLowerCase().includes(query.toLowerCase());

    // Check if the job matches the multi-checkbox filter
    const matchFilter = Object.keys(filterValue).every((key) => {
      // If the filterValue array is empty for this key, return true
      if (filterValue[key].length === 0) {
        return true;
      }
      // Otherwise, check if the item's key value is in the filterValue array
      return filterValue[key].includes(job.specs[key]);
    });

    // Return true if the job matches both filters
    return isMatch && matchFilter;
  });

  // Extract unique data for checkbox
  const uniqueIndustry = [...new Set(jobsData.map((item) => item.specs.industry))].sort();
  const uniqueRegion = [...new Set(jobsData.map((item) => item.specs.region))].sort();
  const uniqueExperienceLevel = [...new Set(jobsData.map((item) => item.specs.experienceLevel))].sort();
  const uniqueEducationLevel = [...new Set(jobsData.map((item) => item.specs.educationLevel))].sort();

  // Array for checkbox
  const filter = [
    { title: "Region", name: "region", array: uniqueRegion },
    { title: "Education", name: "educationLevel", array: uniqueEducationLevel },
    { title: "Experience", name: "experienceLevel", array: uniqueExperienceLevel },
    { title: "Industry", name: "industry", array: uniqueIndustry },
  ];

  // Update the filterValue when user click it
  const handleFilter = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const isChecked = event.target.checked;

    // Create a new copy of the filterValue state
    const updatedFilterValue = { ...filterValue };

    // If the checkbox is checked, add the value to the corresponding array
    if (isChecked) {
      updatedFilterValue[name] = [...updatedFilterValue[name], value];
    } else {
      // If the checkbox is unchecked, remove the value from the corresponding array
      updatedFilterValue[name] = updatedFilterValue[name].filter(
        (filterItem) => filterItem !== value
      );
    }
    // Update the filterValue state with the new array
    setFilterValue(updatedFilterValue);

    // Update the URL query string with the new filters
    setSearchParams({ ...updatedFilterValue });
  };

  const handleSearch = (event) => {
    setQuery(event.target.value);
    if (query) {
      setSearchParams({ title: event.target.value });
    } else {
      setSearchParams({});
    }
  };

  return (
    <div className="mx-12 my-20 md:mx-20 lg:mx-32 xl:mx-44 2xl:mx-[18%]">
      <div>
        <div className="border-b border-gray-200 pb-4lg:pb-10">
          <h1 className="text-4xl font-extrabold text-palm">Jobs</h1>
          <p className="mt-4 text-base text-gray-500">Checkout out the latest job!</p>
        </div>

        {/* mobile content */}
        {mobileFiltersOpen && (
          <div className="fixed inset-0 z-50 lg:hidden">
            <div className="absolute inset-y-0 left-0 flex sm:pr-16">
              <div className="w-screen overflow-y-auto bg-white">
                {/* dialog content */}
                <div className="px-10 py-10">
                  <button
                    type="button"
                    className="text-palm hover:text-gray-500 pb-10"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <HiX className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <p className="mb-4 text-xl font-semibold">Filter</p>
                  {/* search bar */}
                  <div className="flex items-center">
                    <HiOutlineSearch className="mr-2" />
                    <input
                      type="text"
                      value={query}
                      placeholder=""
                      className="w-full max-w-md border-2 rounded-md"
                      onChange={handleSearch}
                    />
                  </div>
                  {/* filter */}
                  <div className="pt-8 flex flex-col space-y-4">
                    {filter.map((item, index) => (
                      <div key={index}>
                        <span>{item.title}</span>
                        {item.array.map((option, optIndex) => (
                          <Checkbox
                            key={optIndex}
                            name={item.name}
                            label={option}
                            value={option}
                            isChecked={filterValue[item.name].includes(option)}
                            onChange={handleFilter}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="pt-8 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
          <button
            type="button"
            className="w-full bg-white flex justify-center items-center shadow rounded-md py-2 mb-6 lg:hidden "
            onClick={() => setMobileFiltersOpen(true)}
          >
            <span className="text-base font-bold text-gray-700">Filters</span>
            <HiOutlineSearch className="ml-3 h-5 w-5" />
          </button>

          <div className="hidden lg:block">
            <form className="divide-y divide-gray-200 space-y-10">
              <div>
                <p className="mb-6 text-xl font-semibold">Filter</p>
                {/* search bar */}
                <div className="flex items-center">
                  <HiOutlineSearch className="mr-2" />
                  <input
                    type="text"
                    value={query}
                    placeholder=""
                    className="w-full max-w-md border-2 rounded-md"
                    onChange={handleSearch}
                  />
                </div>
                {/* filter */}
                <div className="pt-8 flex flex-col space-y-4">
                  {filter.map((item, index) => (
                    <div key={index}>
                      <span>{item.title}</span>
                      {item.array.map((option, optIndex) => (
                        <Checkbox
                          key={optIndex}
                          name={item.name}
                          label={option}
                          value={option}
                          isChecked={filterValue[item.name].includes(option)}
                          onChange={handleFilter}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </form>
          </div>

          {/* Product grid */}
          <div className="mt-6 lg:mt-0 lg:col-span-2 xl:col-span-3">
            <div>
              {filteredData.map((job) => (
                <div key={job.jobId}>
                  <JobCard job={job} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobList;
