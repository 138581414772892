import { Route, Routes } from "react-router-dom";
import JobList from "./pages/JobList";
import JobDetail from "./pages/JobDetail";
import JobApplication from "./pages/JobApplication";
import jobsData from "./assets/Jobs.json";
import "./App.css";

function App() {
  return (
    <Routes>
      <Route path="/" element={<JobList jobsData={jobsData} />} />
      <Route path="/:id" element={<JobDetail />} />
      <Route path="/:id/apply" element={<JobApplication />} />
    </Routes>
  );
}

export default App;
