import React from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import useJobData from "../hook/useJobData";
import BackButton from "../components/BackButton";

const JobApplication = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { id } = useParams();
  const job = useJobData(id);

  const onSubmit = (data) => {
    console.log(data);
    alert("Submitted successfully");
  };

  if (!job) {
    return <div>Job not found</div>;
  }
  return (
    <div className="mx-10 my-20 sm:mx-20 md:mx-40 lg:mx-72 xl:mx-80 text-center 2xl:mx-[30%]">
      <BackButton />
      {/* heading */}
      <div className="xl:my-12">
        <h2 className="text-2xl font-bold leading-7 text-palm md:text-3xl xl:text-4xl">
          {job.title}
        </h2>
        <p className="mt-1 text-sm text-gray-500">Fill in the form for application</p>
      </div>

      <div className="">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  First name*
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    className="shadow-sm h-8 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register("firstName", { required: true, maxLength: 80 })}
                    aria-invalid={errors.firstName ? "true" : "false"}
                  />
                </div>
                {errors.firstName && (
                  <span role="alert" className="text-xs text-red-900">
                    Please enter your first name
                  </span>
                )}
              </div>

              <div className="sm:col-span-1">
                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                  Last name*
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    className="shadow-sm h-8 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register("lastName", { required: true, maxLength: 100 })}
                  />
                </div>
                {errors.lastName && (
                  <span role="alert" className="text-xs text-red-900">
                    Please enter your last name
                  </span>
                )}
              </div>

              <div className="sm:col-span-1">
                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                  Email address*
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    className="shadow-sm h-8 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
                  />
                </div>
                {errors.email && (
                  <span role="alert" className="text-xs text-red-900">
                    Please enter a vaild email
                  </span>
                )}
              </div>

              <div className="sm:col-span-1">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Phone number*
                </label>
                <div className="mt-1">
                  <input
                    type="tel"
                    className="shadow-sm h-8 block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register("phone", { required: true, minLength: 10, maxLength: 10 })}
                  />
                </div>

                {errors.phone && (
                  <span role="alert" className="text-xs text-red-900">
                    Please enter a vaild phone number
                  </span>
                )}
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                  Motivation
                </label>
                <div className="mt-1">
                  <textarea
                    rows={4}
                    className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
                    {...register("motivation", {})}
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="mt-6 inline-flex items-center rounded-md border border-transparent bg-palm px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-lime-800"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobApplication;
