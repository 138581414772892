import React from "react";
import { useParams, Link } from "react-router-dom";
import useJobData from "../hook/useJobData";
import {
  HiOutlineMap,
  HiOutlineAcademicCap,
  HiOutlineBriefcase,
  HiOutlineCalendar,
  HiOutlineLibrary,
  HiOutlineLocationMarker,
  HiOutlineCheck,
} from "react-icons/hi";
import BackButton from "../components/BackButton";

const JobDetail = () => {
  const { id } = useParams();
  const job = useJobData(id);

  if (!job) {
    return <div>Job not found</div>;
  }
  const date = new Date(job.date.timestamp * 1000);
  const options = { year: "numeric", month: "short", day: "numeric" };

  const details = [
    { sepcs: job.specs.region, icon: HiOutlineMap },
    { sepcs: job.specs.city, icon: HiOutlineLocationMarker },
    { sepcs: job.specs.educationLevel, icon: HiOutlineAcademicCap },
    { sepcs: job.specs.experienceLevel, icon: HiOutlineBriefcase },
    { sepcs: job.specs.industry, icon: HiOutlineLibrary },
    { sepcs: `Posted on ${date.toLocaleDateString("nl-NL", options)}`, icon: HiOutlineCalendar },
  ];

  return (
    <div className="mx-12 my-20 md:mx-20 lg:mx-32 xl:mx-44 2xl:mx-[18%]">
      <BackButton />
      {/* heading */}
      <section className="lg:flex lg:items-center lg:justify-between">
        <div className="flex-1">
          <h2 className="text-3xl font-bold leading-7 text-palm md:text-4xl">{job.title}</h2>
          <ul className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap md:mt-2">
            {details.map((item, index) => (
              <li key={index} className="mt-2 flex items-center text-sm text-gray-600 sm:mr-5">
                {React.createElement(item.icon, {
                  className: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                })}
                {item.sepcs}
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-5 flex lg:mt-0 lg:ml-4">
          <Link to={`/${id}/apply`}>
            <span>
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-palm px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-lime-800"
              >
                <HiOutlineCheck className="-ml-1 mr-2 h-5 w-5" />
                Apply now
              </button>
            </span>
          </Link>
        </div>
      </section>

      {/* description */}
      <section className="my-6 lg:my-10">
        <h3 className="text-xl font-bold md:text-2xl">Job description</h3>
        <hr className="mt-1 mb-2" />
        <p className="mt-1 text-gray-600" dangerouslySetInnerHTML={{ __html: job.intro }}></p>
      </section>

      {/* Recruiter */}
      <section className="my-6 lg:my-10">
        <h3 className="text-xl font-bold md:text-2xl">Recruiter</h3>
        <hr className="mt-1 mb-2" />
        <p className="text-gray-600">{job.recruiter}</p>
      </section>
    </div>
  );
};

export default JobDetail;
