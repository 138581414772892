import React from "react";
import { useNavigate } from "react-router-dom";
import { HiOutlineChevronLeft } from "react-icons/hi";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate(-1)} className="mb-2 flex items-center text-gray-600">
      <HiOutlineChevronLeft className="mr-2"/>
      Back
    </button>
  );
};

export default BackButton;
