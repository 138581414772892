import React from "react";
import { Link } from "react-router-dom";
import {
  HiOutlineMap,
  HiOutlineAcademicCap,
  HiOutlineBriefcase,
  HiOutlineCalendar,
  HiOutlineLibrary,
  HiOutlineChevronRight,
} from "react-icons/hi";

const JobCard = (props) => {
  const { job } = props;

  const date = new Date(job.date.timestamp * 1000);
  const options = { year: "numeric", month: "short", day: "numeric" };

  const details = [
    { sepcs: job.specs.region, icon: HiOutlineMap },
    { sepcs: job.specs.educationLevel, icon: HiOutlineAcademicCap },
    { sepcs: job.specs.experienceLevel, icon: HiOutlineBriefcase },
    { sepcs: job.specs.industry, icon: HiOutlineLibrary },
    { sepcs: `Posted on ${date.toLocaleDateString("nl-NL", options)}`, icon: HiOutlineCalendar },
  ];

  return (
    <Link to={`/${job.jobId}`}>
      <div className="bg-white shadow sm:rounded-md mb-6">
        <ul className="divide-y divide-gray-200">
          <li key={job.jobId}>
            <div className="px-4 py-4 sm:px-6">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-palm">{job.title}</p>
                <div className="ml-2 flex-shrink-0 flex">
                  <p className="text-xs text-palm">
                    <HiOutlineChevronRight />
                  </p>
                </div>
              </div>
              <div className="mt-2 sm:flex sm:justify-between">
                <div className="sm:flex">
                  <ul className="flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap">
                    {details.map((item, index) => (
                      <li key={index} className="mt-2 flex items-center text-sm text-gray-600 mr-5">
                        {React.createElement(item.icon, {
                          className: "mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400",
                        })}
                        {item.sepcs}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </Link>
  );
};

export default JobCard;
