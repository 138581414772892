import React from "react";

const Checkbox = (props) => {
  const { name, label, value, isChecked, onChange } = props;

  return (
    <div className="relative flex items-start">
      <div className="flex items-center py-0.5">
        <input
          type="checkbox"
          name={name}
          onChange={onChange}
          value={value}
          checked={isChecked}
          className="focus:ring-lime-500 h-3 w-3 text-lime-600 border-gray-300 rounded"
        />
        <div className="ml-3">
          <label className="text-sm text-gray-700">{label}</label>
        </div>
      </div>
    </div>
  );
};

export default Checkbox;
